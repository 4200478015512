const navRoutes = {
    'manchester-property-maintenance': 'manchester-property-maintenance',
    'salford-property-maintenance': 'salford-property-maintenance',
    'bolton-property-maintenance': 'bolton-property-maintenance',
    'bury-property-maintenance': 'bury-property-maintenance',
    'wigan-property-maintenance': 'wigan-property-maintenance',
    'oldham-property-maintenance': 'oldham-property-maintenance',
    'rochdale-property-maintenance': 'rochdale-property-maintenance',
    'stockport-property-maintenance': 'stockport-property-maintenance',
    'tameside-property-maintenance': 'tameside-property-maintenance',
    'trafford-property-maintenance': 'trafford-property-maintenance',
    'altrincham-property-maintenance': 'altrincham-property-maintenance',
    'ashton-under-lyne-property-maintenance': 'ashton-under-lyne-property-maintenance',
    'bury-central-property-maintenance': 'bury-central-property-maintenance',
    'cheadle-property-maintenance': 'cheadle-property-maintenance',
    'chorlton-property-maintenance': 'chorlton-property-maintenance',
    'didbsury-property-maintenance': 'didbsury-property-maintenance',
    'droylsden-property-maintenance': 'droylsden-property-maintenance',
    'eccles-property-maintenance': 'eccles-property-maintenance',
    'fallowfield-property-maintenance': 'fallowfield-property-maintenance',
    'farnworth-property-maintenance': 'farnworth-property-maintenance',
    'hyde-property-maintenance': 'hyde-property-maintenance',
    'leigh-property-maintenance': 'leigh-property-maintenance',
    'littleborough-property-maintenance': 'littleborough-property-maintenance',
    'middleton-property-maintenance': 'middleton-property-maintenance',
    'moston-property-maintenance': 'moston-property-maintenance',
    'pendlebury-property-maintenance': 'pendlebury-property-maintenance',
    'prestwich-property-maintenance': 'prestwich-property-maintenance',
    'radcliffe-property-maintenance': 'radcliffe-property-maintenance',
    'sale-property-maintenance': 'sale-property-maintenance',
    'shaw-property-maintenance': 'shaw-property-maintenance',
    'swinton-property-maintenance': 'swinton-property-maintenance',
    'wythenshawe-property-maintenance': 'wythenshawe-property-maintenance',
    'tottington-property-maintenance': 'tottington-property-maintenance',
    'urmston-property-maintenance': 'urmston-property-maintenance',
    'whitefield-property-maintenance': 'whitefield-property-maintenance',
    'wythenshawe-property-maintenance': 'wythenshawe-property-maintenance',
    'audenshaw-property-maintenance': 'audenshaw-property-maintenance',
    'burnage-property-maintenance': 'burnage-property-maintenance',
    'deansgate-property-maintenance': 'deansgate-property-maintenance',
    'gorton-property-maintenance': 'gorton-property-maintenance',
    'harpurhey-property-maintenance': 'harpurhey-property-maintenance',
    'hulme-property-maintenance': 'hulme-property-maintenance',
    'longsight-property-maintenance': 'longsight-property-maintenance',
    'moss-side-property-maintenance': 'moss-side-property-maintenance',
    'openshaw-property-maintenance': 'openshaw-property-maintenance',
    'rusholme-property-maintenance': 'rusholme-property-maintenance',
    'stretford-property-maintenance': 'stretford-property-maintenance',
    'whalley-range-property-maintenance': 'whalley-range-property-maintenance',
    'salford-handyman': 'salford-handyman',
    'chorlton-handyman': 'chorlton-handyman',
    'manchester-city-centre-handyman': 'manchester-city-centre-handyman'
    
};

module.exports = navRoutes;
